<template>
    <div class="modal-card" style="width: 100% !important;">
        <header class="modal-card-head">
            <p class="modal-card-title">Uusi yrityskäyttäjä</p>
        </header>
        <section class="modal-card-body">
        <b-field
                                :label="
                                    $t(
                                        'message.register-company.add.user.firstname'
                                    )
                                "
                            >
                                <b-input class="cFirstName" v-model="newUser.first_name"></b-input>
                            </b-field>

                            <b-field
                                :label="
                                    $t(
                                        'message.register-company.add.user.lastname'
                                    )
                                "
                            >
                                <b-input class="cLastName" v-model="newUser.last_name"></b-input>
                            </b-field>

                           
                            <b-field
                                :label="$t('message.partner.add.user.email')"
                                :type="emailFieldType"
                                :message="emailFieldMessage"
                            >
                                <b-input
                                    type="email"
                                    @blur="validateEmail"
                                    value=""
                                    v-model="newUser.email"
                                    :validation-message="
                                        $t('message.error.email-wrong-form')
                                    "
                                >
                                </b-input>
                            </b-field>

                            <b-field
                                :label="$t('message.partner.add.user.phone')"
                                :type="mobileFieldType"
                                :message="mobileFieldMessage"
                            >
                                <b-input v-model="newUser.mobile" @blur="validateMobile"></b-input>
                            </b-field>

                            <b-field
                                :label="$t('message.partner.add.user.password')"
                            >
                                <b-input
                                    type="password"
                                    value=""
                                    v-model="newUser.password"
                                    password-reveal
                                    :validation-message="
                                        $t('message.error.password-mismatch')
                                    "
                                >
                                </b-input>
                            </b-field>
                            <password
                                @input="validateFirstPassword"
                                v-model="newUser.password"
                                :strength-meter-only="true"
                            />
                            <b-field
                                :label="
                                    $t(
                                        'message.partner.add.user.confirm-password'
                                    )
                                "
                                :type="passwordFieldType"
                                :message="passwordFieldMessage"
                            >
                                <b-input
                                    @blur="validatePassword"
                                    type="password"
                                    value=""
                                    v-model="newUser.c_password"
                                    password-reveal
                                    :validation-message="
                                        $t('message.error.password-mismatch')
                                    "
                                >
                                </b-input>
                            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Peruuta"
                @click="$parent.close()" />
            <b-button
                @click="createUser"
                label="Luo käyttäjä"
                type="is-primary" />
        </footer>
    </div>
</template>

<script>
import axios from 'axios';
import Password from "vue-password-strength-meter";

export default {
    props: [
        'company'
    ],
    components: {
        Password
    },
    data() {
        return {
            newUser: {
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                password: "",
                c_password: "",
                lang: "fi",
                company_id: -1,
                type: "company",
            },
            validator: {
                password: true,
                vat: true,
                email: true,
                mobile: true,
                message: {
                    email: "",
                    mobile: "",
                    password: "",
                    vat: ""
                }
            },
        }
    },
    mounted() {
        this.newUser.company_id = this.companyId;
    },
    methods: {
        validateFirstPassword() {
            if(this.newUser.c_password != "") {
                this.validatePassword();
            }
        },
        validatePassword() {
           if(this.newUser.password == this.newUser.c_password && this.newUser.password.length > 6) {    
                this.validator.password = true;
                this.validator.message.password = "";
            } 
            else {
                this.validator.password = false;
                this.validator.message.password = this.$t("message.error.password-mismatch");
                if(this.newUser.password.length < 7) {
                    this.validator.message.password = this.$t("message.error.password-too-short");
                }
            }
        },
        validateEmail() {
            axios.post('users/validate-email',{ email: this.newUser.email}).then((response) => {
                if(response.data.success) {
                    this.validator.email = true;
                    this.validator.message.email = "";
                } else {
                    this.validator.email = false;
                    this.validator.message.email = this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateMobile() {
            axios.post('users/validate-mobile',{ mobile: this.newUser.mobile}).then((response) => {
                if(response.data.success) {
                    this.validator.mobile = true;
                    this.validator.message.mobile = "";
                } else {
                    this.validator.mobile = false;
                    this.validator.message.mobile =  this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateVAT() {
            axios.post('companies/validate-vat',{ vat: this.newCompany.vat}).then((response) => {
                if(response.data.success) {
                    this.validator.vat = true;
                    this.validator.message.vat = "";
                } else {
                    this.validator.vat = false;
                    this.validator.message.vat = this.$t("message.error."+response.data.msg);
                }
            })
        },
        createUser() {
            var uu = this.newUser;
            uu.company_uuid = this.company.uuid;
            this.$store.dispatch("createUser", uu).then((response) => {
                var status = response.status;
                if (
                    status >= 200 &&
                    status < 300
                ) {
                    this.toastSuccess(
                        this.$t(
                            "message.register-company.toaster.user.created"
                        )
                    );
                    this.emit('admin-created-user',response.data);
                    this.$parent.close();
                } else {
                    this.toastFailure(
                        this.$t(
                            "message.register-company.toaster.user.creationError"
                        )
                    );
                }
            })  
        }
    },
    computed: {
         passwordFieldType() {
            if(this.validator.password) {
                return "";
            }
            return "is-danger"
        },
        passwordFieldMessage() {
            if(this.validator.message.password != "") {
                return this.validator.message.password
            }
            return "";
        },
        vatFieldType() {
            if(this.validator.vat) {
                return "";
            }
            return "is-danger"
        },
        vatFieldMessage() {
            if(this.validator.message.vat != "") {
                return this.validator.message.vat
            }
            return "";
        },
        emailFieldType() {
            if(this.validator.email) {
                return "";
            }
            return "is-danger"
        },
        emailFieldMessage() {
            if(this.validator.message.email != "") {
                return this.validator.message.email
            }
            return "";
        },
        mobileFieldType() {
            if(this.validator.mobile) {
                return "";
            }
            return "is-danger"
        },
        mobileFieldMessage() {
            if(this.validator.message.mobile != "") {
                return this.validator.message.mobile
            }
            return "";
        },
        isValid() {
            if(this.newUser.first_name == "" || this.newUser.last_name == "") {
                return false;
            }
            if(this.newUser.email == "" || this.newUser.mobile == "") {
                return false;
            }
            if(this.newUser.password.length < 7 || this.newUser.password != this.newUser.c_password) {
                return false;
            }
            if(this.newCompany.name == "" || this.newCompany.vat == "") {
                return false;
            }
            return true;
        }
    }
}
</script>