<template>
	<div class="dashboard container content">
		<b-modal v-model="dialogIsVisible"
			has-modal-card
			trap-focus
			:destroy-on-hide="false"
			aria-role="dialog"
			aria-label="Example Modal"
			aria-modal>
			<template>
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="modal-card-title">
								{{ $t("message.saldon-tilaus.confirm-order.header") }}
						</p>
						<button
								type="button"
								class="delete"
								@click="confirmDialogClose"
						/>
					</header>
					<section class="modal-card-body">
						<div><p>{{ $t("message.saldon-tilaus.confirm-order.txt") }}</p></div>
						<p>&nbsp;</p>
						<b-button
							:loading="false"
							:label="$t('message.register-company.add.send')"
							size="is-large"
							class="fullwidth"
							type="is-success" 
							v-on:click="send_order" />
					</section>
				</div>
			</template>
		</b-modal>

		<div class="tile is-parent">
			<article class="tile is-child box">
				<div class="columns">
					<div class="column is-full">
						<p class="title">{{ $t(headerMsg) }}</p>
						<section>
							<p v-html="$t('message.saldon-tilaus.txt')"></p>
							<b-field :label="$t('message.saldon-tilaus.add.date-valid')">
								<b-datepicker
									ref="datepicker"
									:month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
									:day-names="['ma','ti','ke','to','pe','la','su']"
									:date-formatter="_formatDate"
									v-model="validity_beginning">
								</b-datepicker>
							</b-field>
							<b-field :label="$t('message.dashboard.list.duration')">
								<b-select v-model="duration">
									<option value="3">3kk</option>
									<option value="6">6kk</option>
									<option value="9">9kk</option>
									<option value="12">12kk</option>
								</b-select>
							</b-field>
							<b-field label="Aseta muutosturvan määrä kaikille työntekijöille kerralla">
								<b-input type="number" v-model="massAsignAmount"></b-input>
								<b-button @click="massAsign">Aseta kaikille</b-button>
							</b-field>
						</section>
						<p>&nbsp;</p>
						<b-table :data="order">
							<b-table-column field="email" label="Työntekijä" width="250" sortable v-slot="props">
								{{ props.row.firstname }} {{ props.row.lastname }} ({{ props.row.email }})
							</b-table-column>

							<b-table-column field="amount" label="Määrä (€)" width="40" numeric sortable v-slot="props">
								<b-input type="number" v-model="props.row.amount" />
							</b-table-column>

							<b-table-column field="validity_beginning" label="Poikkeava voimaantulopäivä" width="40" v-slot="props">
								<b-datepicker
									ref="datepicker"
									:month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
									:day-names="['ma','ti','ke','to','pe','la','su']"
									:date-formatter="_formatDate"
									v-model="props.row.validity_beginning">
								</b-datepicker>
							</b-table-column>
						</b-table>
	
					</div>
				</div>
				<div class="columns">
					<div class="column is-half">
						<h3>Tilaus yhteensä: {{ totalOrderAmount }} &euro;</h3> 
					</div>
					<div class="column is-half">
						<p>
							<b-button
								:loading="false"
								:label="$t('message.register-company.add.send')"
								size="is-large"
								class="fullwidth"
								type="is-success" 
							v-on:click="confirmDialogOpen" />
						</p>
					</div>
				</div>
			</article>
		 </div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	props: {
		saldoType: {
			type: String,
			default: 'muutosturva',
		},
		duunarit: {
			type: Array,
		},
		headerMsg: {
			type: String, 
			default: 'message.order.muutosturva.header'
		},
		company: {
			type: Object
		}
	},
	data() {
		return {
			massAsignAmount: 0,
			dialogIsVisible: false,
			validity_beginning: this.$moment()._d,
			duration: 6,
			orderTemplate: {
				uuid: null, 
				firstname: '',
				lastname: '',
				mobile: '',
				email: '',
				validity_beginning: null,
				amount: 0, 
			},
			order: []
		}
	},
	computed: {
		totalOrderAmount() {
			let total = 0;
			this.order.forEach((orderRow) => {
				total = total + Number(orderRow.amount);
			})
			return total;
		}
	},
	methods: {
		massAsign() {
			this.order.forEach((orderRow, index) => {
				this.order[index].amount = this.massAsignAmount;
			})
		},
		confirmDialogOpen() {
			this.dialogIsVisible = true
		},

		confirmDialogClose() {
			this.dialogIsVisible = false
		},

		
		send_order() {
			let orderCopy = JSON.parse(JSON.stringify(this.order));
			let employees = orderCopy.map((employee) => {
				if(employee.validity_beginning !== null) {
					employee.validity_beginning = this.$moment(employee.validity_beginning).format('YYYY-MM-DD');
				}
				return employee;
			});
			
			let order = {
				type: 'muutosturva',
				validity_beginning: this.$moment(this.validity_beginning).format('YYYY-MM-DD'),
				amount: this.totalOrderAmount,
				company_id: this.company.id,
				meta: {
					employees: employees,
					duration: this.duration
				}
			}
			console.log(order);
			axios.post('/orders',order).then((response) => {
				if(response.status >= 200 && response.status < 300) { 
					this.toastSuccess("Tilaus onnistui!")
					this.confirmDialogClose();
					this.$emit('close');
				} 
				else {
					this.toastFailure("Tilaus epäonnistui!");
				}
			}).catch((error) => {
				this.ERROR(error);
				this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-order'));
			});
		},
	},
	mounted() {
		this.order=[];
		this.duunarit.forEach((duunari) => {
			let tilausrivi = JSON.parse(JSON.stringify(this.orderTemplate));
			tilausrivi.uuid = duunari.uuid;
			tilausrivi.lang = duunari.lang;
			tilausrivi.firstname = duunari.first_name;
			tilausrivi.lastname = duunari.last_name;
			tilausrivi.email = duunari.email;
			tilausrivi.mobile = duunari.mobile;
			this.order.push(tilausrivi);
		})
	}
}
</script>
