var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-card",staticStyle:{"width":"100% !important"}},[_vm._m(0),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"label":_vm.$t(
                                    'message.register-company.add.user.firstname'
                                )}},[_c('b-input',{staticClass:"cFirstName",model:{value:(_vm.newUser.first_name),callback:function ($$v) {_vm.$set(_vm.newUser, "first_name", $$v)},expression:"newUser.first_name"}})],1),_c('b-field',{attrs:{"label":_vm.$t(
                                    'message.register-company.add.user.lastname'
                                )}},[_c('b-input',{staticClass:"cLastName",model:{value:(_vm.newUser.last_name),callback:function ($$v) {_vm.$set(_vm.newUser, "last_name", $$v)},expression:"newUser.last_name"}})],1),_c('b-field',{attrs:{"label":_vm.$t('message.partner.add.user.email'),"type":_vm.emailFieldType,"message":_vm.emailFieldMessage}},[_c('b-input',{attrs:{"type":"email","value":"","validation-message":_vm.$t('message.error.email-wrong-form')},on:{"blur":_vm.validateEmail},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})],1),_c('b-field',{attrs:{"label":_vm.$t('message.partner.add.user.phone'),"type":_vm.mobileFieldType,"message":_vm.mobileFieldMessage}},[_c('b-input',{on:{"blur":_vm.validateMobile},model:{value:(_vm.newUser.mobile),callback:function ($$v) {_vm.$set(_vm.newUser, "mobile", $$v)},expression:"newUser.mobile"}})],1),_c('b-field',{attrs:{"label":_vm.$t('message.partner.add.user.password')}},[_c('b-input',{attrs:{"type":"password","value":"","password-reveal":"","validation-message":_vm.$t('message.error.password-mismatch')},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}})],1),_c('password',{attrs:{"strength-meter-only":true},on:{"input":_vm.validateFirstPassword},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}}),_c('b-field',{attrs:{"label":_vm.$t(
                                    'message.partner.add.user.confirm-password'
                                ),"type":_vm.passwordFieldType,"message":_vm.passwordFieldMessage}},[_c('b-input',{attrs:{"type":"password","value":"","password-reveal":"","validation-message":_vm.$t('message.error.password-mismatch')},on:{"blur":_vm.validatePassword},model:{value:(_vm.newUser.c_password),callback:function ($$v) {_vm.$set(_vm.newUser, "c_password", $$v)},expression:"newUser.c_password"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"label":"Peruuta"},on:{"click":function($event){return _vm.$parent.close()}}}),_c('b-button',{attrs:{"label":"Luo käyttäjä","type":"is-primary"},on:{"click":_vm.createUser}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Uusi yrityskäyttäjä")])])
}]

export { render, staticRenderFns }