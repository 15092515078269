<template>
    <div class="modal-card" style="width: 100% !important;">
        <header class="modal-card-head">
            <p class="modal-card-title">Uusi työntekijä</p>
        </header>
        <section class="modal-card-body">
            <b-field
                :label="
                    $t(
                        'message.register-company.add.user.firstname'
                    )
                "
            >
                <b-input class="cFirstName" v-model="newEmployee.first_name"></b-input>
            </b-field>

            <b-field
                :label="
                    $t(
                        'message.register-company.add.user.lastname'
                    )
                "
            >
                <b-input class="cLastName" v-model="newEmployee.last_name"></b-input>
            </b-field>

            
            <b-field
                :label="$t('message.partner.add.user.email')"
            >
                <b-input
                    type="email"
                    value=""
                    v-model="newEmployee.email"
                    :validation-message="
                        $t('message.error.email-wrong-form')
                    "
                >
                </b-input>
            </b-field>

            <b-field
                :label="$t('message.partner.add.user.phone')"
            >
                <b-input v-model="newEmployee.mobile"></b-input>
            </b-field>
            <b-field label="Kieli">
            <b-select v-model="newEmployee.lang" placeholder="Valitse kieli">
                <option value="fi">SUOMI</option>
                <option value="en">ENGLANTI</option>
            </b-select>
        </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Peruuta"
                @click="$parent.close()" />
            <b-button
                @click="createEmployee"
                label="Luo työntekijä"
                type="is-primary" />
        </footer>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: [
        'company'
    ],
    data() {
        return {
            newEmployee: {
                first_name: '',
                last_name: '',
                email: '',
                mobile: '',
                company_id: 0,
                notify: false, 
                lang: 'fi'
            },
            validator: {
                password: true,
                vat: true,
                email: true,
                mobile: true,
                message: {
                    email: "",
                    mobile: "",
                    password: "",
                    vat: ""
                }
            },
        }
    },
    mounted() {
        this.newEmployee.company_id = this.company.id;
    },
    methods: {
        createEmployee() {
            this.newEmployee.company_id = this.company.id
            axios.post("/employees", this.newEmployee).then((response) => {
                var status = response.status
                if (status >= 200 && status < 300) { 
                    this.emit('admin-created-employee',response.data);
                    this.toastSuccess(this.$t('message.saldon-tilaus.toaster.success.employee-created', {email: response.data.employee.email}));
                    this.$parent.close();
                } else {
                    this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-employee'));
                }
            }).catch((error) => {
                this.ERROR(error);
                this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-employee'));
            });
        },
    },   
}
</script>